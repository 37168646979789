import { axiosClient } from "../axios";

export function getRoleCandidacyByUser(userId) {
  return axiosClient.get(`/v1/role-candidacies/${userId}`);
}

export function enableTests(id) {
  return axiosClient.post(`/v1/role-candidacies/${id}/enable-test`);
}

export function insertRoleCandidacy(data) {
  return axiosClient.post(`/v1/role-candidacies/insert`, data);
}

export function deleteRoleCandidacy(roleId) {
  return axiosClient.delete(`/v1/role-candidacies/${roleId}/delete`);
}
