import { axiosClient } from "../axios";

export function insertRequestedCompensation(data) {
  return axiosClient.post(`/v1/requested-compensations/insert`, data);
}

export function updateRequestedCompensation(compensationId, data) {
  return axiosClient.post(
    `/v1/requested-compensations/${compensationId}/update`,
    data
  );
}

export function deleteRequestedCompensation(compensationId) {
  return axiosClient.delete(
    `/v1/requested-compensations/${compensationId}/delete`
  );
}
