import { InternalJobPositionApi } from "@/api";
import { showSuccessSnackbar } from "@/utils/snackbar";
import { downloadBlob } from "@/utils/file";

export async function getAll(options) {
  const { data } = await InternalJobPositionApi.getAll(options);
  return data;
}

export async function get(id) {
  const { data } = await InternalJobPositionApi.get(id);
  return data;
}

export async function getByUser(userId) {
  const { data } = await InternalJobPositionApi.getByUser(userId);
  return data;
}

export async function addByUser(userId, payload) {
  const { data } = await InternalJobPositionApi.addByUser(userId, payload);
  return data;
}

export async function getByCc(ccId, options) {
  const { data } = await InternalJobPositionApi.getByCc(ccId, options);
  return data;
}

export async function insert(payload) {
  const { status, data } = await InternalJobPositionApi.addNewPosition(payload);
  showSuccessSnackbar(status, "Position inserted successfully");
  return data;
}

export async function update(id, payload) {
  const { status } = await InternalJobPositionApi.updatePosition(id, payload);
  showSuccessSnackbar(status, "Position updated successfully");
}

export async function suspend(id) {
  const { status } = await InternalJobPositionApi.suspendPosition(id);
  showSuccessSnackbar(status, "Position status updated successfully");
}

export async function close(id, payload) {
  const { status } = await InternalJobPositionApi.closePosition(id, payload);
  showSuccessSnackbar(status, "Position closed successfully");
}

export async function uploadIncomingResume(id, payload) {
  const { data } = await InternalJobPositionApi.uploadIncomingResume(
    id,
    payload
  );
  return data;
}

export async function updateIncomingResume(payload) {
  const { data } = await InternalJobPositionApi.updateIncomingResume(payload);
  return data;
}

export async function notifyIncomingResume(id, payload) {
  await InternalJobPositionApi.notifyIncomingResume(id, payload);
}

export async function downloadIncomingResume(id) {
  const response = await InternalJobPositionApi.downloadIncomingResume(id);
  downloadBlob(response);
  showSuccessSnackbar(response.status, "User resume successfully downloaded.");
}

export async function updateIncomingResumeStatus(payload) {
  const response = await InternalJobPositionApi.updateIncomingResumeStatus(
    payload
  );
  showSuccessSnackbar(response.status, "User status successfully updated.");
}

export async function removeResume(id) {
  const response = await InternalJobPositionApi.removeIncomingResume(id);
  showSuccessSnackbar(response.status, "User resume successfully removed.");
  return response.data;
}
