import { axiosClient } from "../axios";

export function getUserWorkExperiences(id) {
  return axiosClient.get(`/v1/work-experiences/${id}`);
}

export function insertSingleWorkExperience(data) {
  return axiosClient.post("/v1/work-experiences/insert", data);
}

export function insertMultipleWorkExperiences(userId, data) {
  return axiosClient.post(
    `/v1/work-experiences/insert/${userId}/multiple`,
    data
  );
}

export function updateSingleWorkExperience(data) {
  return axiosClient.post(`/v1/work-experiences/${data.id}/update`, data);
}

export function remove(workExpId) {
  return axiosClient.delete(`/v1/work-experiences/${workExpId}/delete`);
}
