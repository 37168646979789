import { axiosClient } from "../axios";

export function getAllRegions() {
  return axiosClient.get(`v1/regions/`);
}

export function getProvinceForRegion(regionId) {
  return axiosClient.get(`v1/provinces?regionId=${regionId}`);
}

export function getMunicipalityInProvince(provinceId) {
  return axiosClient.get(`v1/municipalities?provinceId=${provinceId}`);
}
