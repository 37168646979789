import { axiosClient } from "../axios";

export function getUserItSkills(id) {
  return axiosClient.get(`/v1/it-skills/${id}`);
}

export function insertSingleItSkill(data) {
  return axiosClient.post("/v1/it-skills/insert", data);
}

export function insertMultipleItSkills(userId, data) {
  return axiosClient.post(`/v1/it-skills/insert/${userId}/multiple`, data);
}

export function updateSingleItSkill(data) {
  return axiosClient.post(
    `/v1/it-skills/${data.userId}/${data.topic}/update`,
    data
  );
}

export function remove({ userId, topic }) {
  return axiosClient.delete(`/v1/it-skills/${userId}/${topic}/delete`);
}
