import { axiosClient } from "../axios";

export function getUserQualifications(id) {
  return axiosClient.get(`/v1/qualifications/${id}`);
}

export function insertSingle(data) {
  return axiosClient.post("/v1/qualifications/insert", data);
}

export function insertMultipleQualifications(userId, data) {
  return axiosClient.post(`/v1/qualifications/insert/${userId}/multiple`, data);
}

export function update(data) {
  return axiosClient.post(`/v1/qualifications/${data.id}/update`, data);
}

export function remove(qualificationId) {
  return axiosClient.delete(`/v1/qualifications/${qualificationId}/delete`);
}
