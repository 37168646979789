import { axiosClient } from "../axios";

export function getUserLanguageSkill(id) {
  return axiosClient.get(`/v1/language-skills/${id}`);
}

export function insert(data) {
  return axiosClient.post("/v1/language-skills/insert", data);
}

export function insertMultipleLanguageSkill(userId, data) {
  return axiosClient.post(
    `/v1/language-skills/insert/${userId}/multiple`,
    data
  );
}

export function update(data) {
  return axiosClient.post(
    `/v1/language-skills/${data.userId}/${data.language}/update`,
    data
  );
}

export function remove({ userId, language }) {
  return axiosClient.delete(`/v1/language-skills/${userId}/${language}/delete`);
}
