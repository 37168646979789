import { axiosClient } from "../axios";

const baseUrl = "/v1/interview";

export function insertInterview(data) {
  return axiosClient.post(`${baseUrl}/insert`, data);
}

export function updateInterview(data) {
  return axiosClient.post(`${baseUrl}/${data.id}/update`, data);
}

export function deleteInterview(id) {
  return axiosClient.delete(`${baseUrl}/${id}/delete`);
}
