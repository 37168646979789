export default [
  {
    path: "/",
    name: "home",
    redirect: { name: "public-positions" },
    meta: { requiresAuth: false, allowedRoles: [] },
  },
  {
    path: "/public/positions",
    name: "public-positions",
    component: () =>
      import(
        /* webpackChunkName: "public" */ "@/views/public/JobPositionsPublicView.vue"
      ),
    meta: {
      requiresAuth: false,
      allowedRoles: [],
      header: true,
      disableFillHeight: true,
      removeZeroPadding: true,
    },
  },
  {
    path: "/legal/privacy-policy",
    name: "privacy-policy",
    component: () =>
      import(
        /* webpackChunkName: "public" */ "@/views/public/PrivacyPolicyView.vue"
      ),
    meta: { requiresAuth: false, allowedRoles: [], header: true },
  },
  {
    path: "/legal/careers-privacy-policy",
    name: "privacy-policy-careers",
    component: () =>
      import(
        /* webpackChunkName: "public" */ "@/views/public/CareersPrivacyPolicyView.vue"
      ),
    meta: { requiresAuth: false, allowedRoles: [], header: true },
  },
];
