import { RegistrationApi } from "@/api";
import { showSuccessSnackbar } from "@/utils/snackbar";
import Router from "@/router";
import Store from "@/store";

export async function register(positionId, data) {
  await RegistrationApi.registerUser(positionId, data).then(() => {
    Router.push({ name: "registration-thank-you" });
  });
}

export async function validateEmail(token) {
  const response = await RegistrationApi.validateEmail(token);
  showSuccessSnackbar(response.status, response.data.message);
}

export async function renewToken(token) {
  const response = await RegistrationApi.renewToken(token);
  showSuccessSnackbar(response.status, response.data.message);
}

export async function finalizeRegistration(userId, data) {
  const response = await RegistrationApi.finalizeRegistration(userId, data);
  showSuccessSnackbar(response.status, response.data.message);
  Store.dispatch(
    "user/setRegistrationCompletedTs",
    response.data.regCompletedTs
  );
  Router.push({
    name: "dashboard",
  });
}
