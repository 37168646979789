import { axiosClient } from "../axios";

export function geTestsByUserId(userId) {
  return axiosClient.get(`/v1/tests/${userId}`);
}

export function checkUserTest(userId, testId) {
  return axiosClient.get(`/v1/tests/${userId}/check/${testId}`);
}

export function checkUserTestByRoleId(userId, roleId) {
  return axiosClient.get(`/v1/tests/${userId}/check-by-role/${roleId}`);
}

export function getLogicTest() {
  return axiosClient.get(`/v1/tests/logic`);
}

export function getTestByRole(roleId) {
  return axiosClient.get(`/v1/tests/role/${roleId}`);
}

export function submitTest(testId, data) {
  return axiosClient.post(`/v1/tests/submit/${testId}`, data);
}
