import { axiosClient } from "../axios";

export function insertNote(data) {
  return axiosClient.post("/v1/notes/insert", data);
}
export function updatetNote(data) {
  return axiosClient.post(`/v1/notes/${data.id}/update`, data);
}

export function deleteNote(id) {
  return axiosClient.delete(`/v1/notes/${id}/delete`);
}
