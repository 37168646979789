import { axiosClient } from "../axios";

export function registerUser(positionId, data) {
  return axiosClient.post(
    `/v1/registration/create-user?position=${positionId}`,
    data
  );
}

export function validateEmail(token) {
  return axiosClient.post(`/v1/registration/validate?token=${token}`);
}

export function renewToken(token) {
  return axiosClient.post(`/v1/registration/renew-token?token=${token}`);
}

export function finalizeRegistration(userId, data) {
  return axiosClient.post(`/v1/registration/${userId}/finalize`, data);
}
